<!-- eslint-disable vue/no-v-html -->
<template>
  <CommonsModalsModalBody :svg="svg" height="auto">
    <template #title> Envoyer par e-mail </template>

    <template #help>
      <div class="uds-paragraph" v-html="help" />
    </template>

    <template #content>
      <div class="modal-content ssd-container">
        <div class="ssd-history">
          <div class="ssd-title">Historique des envois</div>
          <div v-if="mailsHistory.length === 0" class="ssd-nohistory">
            Ce document n’a jamais été envoyé.
          </div>
          <div v-else class="ssd-history-table">
            <div class="ssdh-table-row --header">
              <div class="cell --date">Date d'envoi</div>
              <div class="cell">Destinataire / Sujet</div>
            </div>
            <div v-for="(mail, indexMail) in mailsHistory" :key="indexMail" class="ssdh-table-row">
              <div class="cell --date">
                <template v-if="dayjs(mail.createdAt).isValid()">
                  {{ dayjs(mail.createdAt).format("DD/MM/YYYY") }}
                </template>
                <template v-else>-</template>
              </div>
              <div class="cell">
                {{ mail.to }}<span>{{ mail.subject }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="ssd-form">
          <div class="ssd-title">Envoyer un e-mail</div>
          <div class="ssd-row">
            <uds-input-string
              label="À *"
              :value="form.to"
              :error="err.to"
              @change="form.to = $event || null"
            />
          </div>
          <div class="ssd-row">
            <uds-input-string
              label="Copie"
              :value="form.cc"
              :error="err.cc"
              @change="form.cc = $event || null"
            />
            <uds-input-string
              label="Copie cachée"
              :value="form.bcc"
              :error="err.bcc"
              @change="form.bcc = $event || null"
            />
          </div>
          <div class="ssd-row">
            <uds-input-string
              label="Objet *"
              :value="form.subject"
              :error="err.subject"
              @change="form.subject = $event || ''"
            />
          </div>
          <div class="ssd-row">
            <div>
              <label class="uds-input-label">Message <span style="color: #e02424">*</span></label>
              <CommonsInputsTextEditor v-model="form.content" />
            </div>
          </div>
          <div class="ssd-row-files">
            <div v-for="(file, indexFile) in localFiles" :key="file.id" class="ssd-file-container">
              <uds-icon icon-name="attach_file" :color="udsColors.udsNeutral800" />
              {{ file.name }}

              <uds-icon
                v-if="localFiles.length > 1"
                icon-name="delete"
                :color="udsColors.udsNeutral800"
                @click="localFiles.splice(indexFile, 1)"
              />
            </div>
            <div v-if="err.files" class="row-error">
              {{ err.files }}
            </div>
          </div>
          <div
            v-if="
              hasOneOfTheseBrands([
                EBrandNames.COMPTALIB,
                EBrandNames.CLEMENTINE,
                EBrandNames.POURCENTAGE,
              ]) && !isImported
            "
            class="ssd-row"
            style="padding-left: 2px"
          >
            <uds-input-checkbox
              label="Masquer l’en-tête par défaut dans les e-mails"
              :is-checked="shouldHideEmailHeader"
              :disabled="!isPrenium"
              :item-info="
                isPrenium
                  ? `Tous vos e-mails sont envoyés avec une en-tête par défaut. Votre logo y est
                      directement intégré si vous l’avez importé dans “Modifier l’apparence des documents”.`
                  : 'Option uniquement disponible dans la version payante.'
              "
              @input="shouldHideEmailHeader = $event"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <uds-main-button
        size="small"
        :loading="isLoading"
        :disabled="!isValid"
        @click="isValid && sendMail()"
      >
        <uds-icon
          class="btn-icon"
          icon-name="send"
          color="white"
          size="small"
          style="margin-right: 8px"
        />
        Envoyer
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { CreateCommercialMail, ICommercialMail, IFile } from "@silexpert/core";
import { SubscriptionType } from "@silexpert/core";

const salesStore = useSalesStore();
const societyStore = useSocietyStore();
const { hasOneOfTheseBrands } = useBrandsComposable();
const dayjs = useDayjs();
const emit = defineEmits(["cancel", "close"]);

const props = defineProps<{
  help: string;
  svg?: string;
  files: IFile[];
  frenchName: string;
  frenchNameWithDefiniteArticle: string;
  defaultEmail?: string;
  isImported?: boolean;
}>();

const udsColors = getUdsColors();

const subjectWithFrenchName = computed<string>(
  () => `Votre ${props.frenchName.charAt(0).toUpperCase()}${props.frenchName.slice(1)}`,
);

const isLoading = ref(false);
const mailsHistory = ref<ICommercialMail[]>([]);
const localFiles = ref<IFile[]>([]);
const form = ref<CreateCommercialMail>({
  to: props.defaultEmail ?? "",
  cc: undefined,
  bcc: undefined,
  subject: subjectWithFrenchName.value,
  content: `<p>Vous trouverez ci-joint ${props.frenchNameWithDefiniteArticle}.</p><br/><p>Merci de votre confiance !</p><br/><br/><p>${societyStore?.society?.name}</p>`,
});

const err = computed<{ [key: string]: string | null }>(() => {
  const { to, subject, content } = form.value;

  return {
    to: !isEmailString(to) ? "L'adresse e-mail est invalide." : null,
    subject: (subject?.length ?? 0) <= 0 ? "L'objet ne doit pas être vide" : null,
    files: localFiles.value.length === 0 ? "Au moins un fichier est requis" : null,
    content: content.length === 0 ? "Le message ne doit pas être vide" : null,
  };
});

const isValid = computed(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

const settings = computed(() => salesStore.settings);
const isPrenium = computed(
  () => societyStore.society?.idSubscriptionType !== SubscriptionType.FREEMIUM,
);

const shouldHideEmailHeader = computed({
  get() {
    return settings.value?.shouldHideEmailHeader ?? false;
  },
  set(value) {
    salesStore.updateOrCreateSettings({
      shouldHideEmailHeader: isPrenium.value ? value : false,
    });
  },
});

onMounted(async () => {
  localFiles.value = props.files;
  await getMails();
});

async function getMails() {
  if (isDefined(props.files[0].id)) {
    await $silex()
      .invoice.getAllMails(props.files[0].id)
      .then((res: ICommercialMail[]) => {
        mailsHistory.value = res;
      })
      .catch((error: any) => {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      });
  }
}

async function sendMail() {
  const { to, cc, bcc, subject, content } = form.value;
  isLoading.value = true;

  await $silex()
    .invoice.sendMailWithMultipleFiles({
      // @ts-expect-error
      idsFile: localFiles.value.map((f) => f.id),
      to,
      ...(isDefined(cc) ? { cc } : {}),
      ...(isDefined(bcc) ? { bcc } : {}),
      subject,
      content,
    })
    .catch((error: any) => {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    });

  isLoading.value = false;
  emit("close");
}
</script>

<style lang="scss" scoped>
.ssd-container {
  font-style: normal;
  font-weight: $uds-weight-450;
  display: flex;
  flex-direction: column;
  gap: $uds-spacing-3;
  max-height: 670px;
  padding-right: 10px;
  padding-bottom: 10px;
  .ssd-title {
    font-weight: $uds-weight-500;
    font-size: 18px;
    line-height: 150%;
  }
  .ssd-history-table {
    margin-top: $uds-spacing-1;
    border: 0.5px solid $uds-neutral-600;
    box-shadow: 0px 2px 5px rgba(1, 34, 223, 0.05);
    border-radius: 10px;
    .ssdh-table-row {
      display: flex;
      align-items: center;
      padding: $uds-spacing-1 $uds-spacing-2;
      width: auto;
      border-top: 0.5px solid $uds-neutral-600;
      &.--header {
        font-size: 14px;
        line-height: 150%;
        color: $uds-neutral-700;
        border-top: 0;
      }
      .cell {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        &.--date {
          width: 110px;
        }
        span {
          color: $uds-neutral-700;
          padding-top: $uds-spacing-0_5;
        }
      }
    }
  }
  .ssd-nohistory {
    margin-top: $uds-spacing-1;
    color: $uds-neutral-700;
    font-size: 14px;
  }
  .ssd-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 14px;

    .ssd-row {
      display: flex;
      gap: $uds-spacing-1;

      > * {
        flex-basis: 100%;
        flex-grow: 1;
        height: fit-content;
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }

  .ssd-row-files {
    display: flex;
    gap: $uds-spacing-1;
    .ssd-file-container {
      display: flex;
      gap: $uds-spacing-1;
      padding: $uds-spacing-0_5 $uds-spacing-1;
      align-items: center;
      background-color: $uds-neutral-200;
      border-radius: $uds-radius-1;
    }
  }

  .row-error {
    color: #771d1d;
  }
}

.footer-actions {
  text-align: right;

  .btn-icon {
    margin-right: $uds-spacing-1;
  }
}
</style>
<style lang="scss" scoped>
.ssd-row {
  // Override wysiwyg input
  .quillWrapper {
    border-radius: 5px;
  }
  .ql-container {
    border: 1px solid $uds-neutral-400;
    border-top: 1px solid $uds-neutral-400 !important;
    border-radius: $uds-radius-1;
    margin-top: 2px;
  }
  .ql-toolbar {
    display: none;
  }
  .ql-editor {
    font-size: 14px;
    padding: $uds-spacing-1;
    height: 150px;
  }
}
</style>
